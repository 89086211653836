import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import loadable from '@loadable/component'

import { useWindowSize } from '../../helpers/useWindowSize'

const Availability = loadable(() => import('../Availability'))

const PhoneNumber = () => {
  const windowSize = useWindowSize()

  const data = useStaticQuery(graphql`
    query {
      globalHeader: sanityGlobalHeader {
        tel
      }
    }
  `)

  const { tel } = data.globalHeader

  return (
    <div className="leading-4">
      {windowSize.width < 768 && (
        <a className="text-12 md:text-14" href={`tel:${tel}`}>
          {tel}
        </a>
      )}
      {windowSize.width >= 768 && (
        <span className="text-12 md:text-14">{tel}</span>
      )}
      <br />
      <Availability />
    </div>
  )
}

export default PhoneNumber
